
.timeline {
  .steps {
    position: relative;
    margin-top: 32px;
  }
  .steps::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: white;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    left: 50%;
  }

  .steps .content p {
    font-size: 16px;
  }

  .steps .content h2 {
    font-weight: 600;
    font-size: 16px;
    color: #676767;
  }

  .steps-container {
    position: relative;
    background-color: inherit;
    width: calc(50% + 32px);
  }

  .steps-container .content {
    padding: 32px;
    background-color: #013A40;
    border: 1px solid #05DBF2;
    position: relative;
    border-radius: 0px 0px 80px 0px;
    color: #fff;
  }

  .steps .steps-container:nth-child(even) {
    left: calc(50% - 32px);
    flex-direction: row-reverse;
  }

  .steps-container {
    display: flex;
  }

  .steps .steps-container .date {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 10px;
    width: 62px;
    height: 62px;
    background: linear-gradient(135deg, #013A40 0%,
            #013A40 50%,
            #013A40 50%,
            #049dbf 100%);
    border: 1px solid #013A40;
    font-size: 75%;
    border-radius: 50%;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 777;
    text-align: center;
    color: #fff;
  }

  .steps .steps-container .date.date-end {
    right: 2px;
    position: absolute;
    bottom: 0;
  }

  .steps .steps-container:nth-child(even) .date.date-end{
    right: unset;
    left: 2px;
  }

  .step-line {
    width: 40px;
    background-color: #fff;
    height: 1px;
    margin-top: 31px;
    opacity: 0.4;
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    .steps::after {
      left: 32px;
    }
    .steps-container {
      left: 0;
      flex-direction: row-reverse;
      width: auto;
      margin-bottom: 16px;
    }
    .steps .steps-container:nth-child(even) {
      left: 0;
    }

    .steps .steps-container .date.date-end {
      right: unset;
      position: absolute;
      bottom: 0;
      left: 2px;
    }
  }
}