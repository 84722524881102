.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  align-content: center;
  display: flex;
  flex-flow: column;
  z-index: -1;
  
  video {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
}