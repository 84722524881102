.lang-section-cover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0, 0.65);
    z-index: -1;
  }
}
.lang-icon-img {
  width: 80%;
  filter: drop-shadow(0 0 0.25rem #fff);
  @media(min-width: 600px){
    width: 40%;
  }

  @media(min-width: 900px){
    width: 50%;
  }
}