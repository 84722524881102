.profile-section-cover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.25);
    z-index: -1;

  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../../../public/img/ProfilePic_Transparent.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: auto 250px;
    z-index: -1;

    @media (min-width: 600px) {
      background-size:250px auto;
    }

    @media (min-width: 900px) {
      background-size:300px auto;
    }

    @media (min-width: 1200px) {
      background-size:350px auto;
    }
  }
}